import React from 'react'
import * as Icon from 'react-feather';
import Cbt from '../projects/Cbt';
import CbtReport from '../projects/CbtReport';
import Procsi from '../projects/Procsi';
import CProc from '../projects/CProc';
import InputAutomation from '../projects/InputAutomation';
import MsiKuesioner from '../projects/MsiKuesioner';
import UmSbyFaculty from '../projects/UmSbyFaculty';
import UmSbyForm from '../projects/UmSbyForm';
import BramindraIndotama from '../projects/BramindraIndotama';
import MyVote from '../projects/MyVote';

export const awardsData = [
    {
        position: '2nd Place',
        title: 'LKS Web Design Competition at East Java Province Level',
        year: '2019',
        instance: 'Dinas Pendidikan East Java Province',
        Icon: Icon.Award,
    },
    {
        position: '4th Place',
        title: 'LKS Web Design Competition at Wilker I East Java Province Level',
        year: '2018',
        instance: 'Dinas Pendidikan East Java Province',
        Icon: Icon.Award,
    },
    {
        position: 'Semi-finalist',
        title: 'National Smart Hackathon 2018 in Jakarta',
        year: '2018',
        instance: 'Kementerian Komunikasi dan Informatika',
        Icon: Icon.Award,
    },
]

export const expertiseData = [
    {
        title: 'React',
        desc: 'Expert in building dynamic, user-friendly interfaces with reusable components and clean code.',
        Image: () => (
            <img
                className='w-10'
                src='https://cdn1.iconfinder.com/data/icons/soleicons-fill-vol-1/64/reactjs_javascript_library_atom_atomic_react-512.png'
                alt='react'
            />
        ),
        // Icon: Icon.Code,
    },
    {
        title: 'TypeScript',
        desc: 'Enhances JavaScript reliability and scalability with static typing.',
        Image: () => (
            <img
                className='w-10'
                src='https://static-00.iconduck.com/assets.00/typescript-icon-icon-1024x1024-vh3pfez8.png'
                alt='typescript'
            />
        ),
        // Icon: Icon.Code,
    },
    {
        title: 'Tailwind',
        desc: 'Skilled in rapid, responsive UI development with a utility-first approach.',
        Image: () => (
            <img
                className='h-8 mb-6'
                src='https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/320px-Tailwind_CSS_Logo.svg.png'
                alt='tailwind'
            />
        ),
        // Icon: Icon.Code,
    },
    {
        title: 'Operating Linux',
        desc: 'Adept at using Linux commands for server configuration, software installation, and application deployment.',
        Image: () => (
            <img
                className='h-8 mb-6'
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Windows_Terminal_logo.svg/1200px-Windows_Terminal_logo.svg.png"
                alt='linux'
            />
        )
        // Icon: Icon.Code,

    },
    // {
    //     title:'Developing',
    //     Icon: Icon.Monitor
    // },
    // {
    //     title:'Mac OS',
    //     Icon: Icon.StopCircle
    // },
    // {
    //     title:'Cinema',
    //     Icon: Icon.Video
    // },
    // {
    //     title:'Coffee',
    //     Icon: Icon.Coffee
    // },
    // {
    //     title:'Music',
    //     Icon: Icon.Music
    // },
    // {
    //     title:'Games',
    //     Icon: Icon.Watch
    // },
    // {
    //     title:'Designing',
    //     Icon: Icon.Box
    // },
    // {
    //     title:'Sports',
    //     Icon: Icon.Target
    // },
    // {
    //     title:'Painting',
    //     Icon: Icon.PenTool
    // },
    // {
    //     title:'Reading',
    //     Icon: Icon.Book
    // },
    // {
    //     title:'Android',
    //     Icon: Icon.Smartphone
    // },
    // {
    //     title:'Other Activity',
    //     Icon: Icon.Activity
    // },
]
export const servicesData = [
    {
        title: 'Custom Web Application',
        desc: 'Crafting tailored web applications with React and TypeScript, delivering both functionality and finesse to suit your specific business needs.',
        Icon: Icon.Code,
    },
    {
        title: 'Slicing UI Design',
        desc: 'Expertly transforming UI designs into fully functional, responsive websites with a keen eye for detail, ensuring pixel-perfect accuracy and optimal user experience.',
        Icon: Icon.Layout,
    },
    {
        title: 'Responsive UI/UX Design',
        desc: 'Utilizing Tailwind CSS to design responsive, visually appealing user interfaces that provide seamless user experiences across all devices.',
        Icon: Icon.Smartphone,
    },
    {
        title: 'Cross-Platform Desktop Applications',
        desc: 'Developing versatile, cross-platform desktop applications with Electron, bridging the gap between web and desktop environments.',
        Icon: Icon.Monitor,
    },
    {
        title: 'Bug Fixing',
        desc: 'Specializing in identifying and resolving complex bugs in React applications, ensuring smooth, error-free user experiences and enhancing overall app performance.',
        Icon: Icon.Tool,
    },
    {
        title: 'Code Optimization and Refactoring',
        desc: 'Enhancing existing codebases with TypeScript for improved performance, maintainability, and scalability.',
        Icon: Icon.Cpu,
    },
    // {
    //     title: 'UX / UI Design',
    //     desc: 'The phrasal sequence of the is now so that many campaign and benefit',
    //     Icon: Icon.Airplay
    // },
    // {
    //     title: 'Ios App Designer',
    //     desc: 'The phrasal sequence of the is now so that many campaign and benefit',
    //     Icon: Icon.Aperture
    // },
    // {
    //     title: 'Photography',
    //     desc: 'The phrasal sequence of the is now so that many campaign and benefit',
    //     Icon: Icon.Camera
    // },
    // {
    //     title: 'Graphic Designer',
    //     desc: 'The phrasal sequence of the is now so that many campaign and benefit',
    //     Icon: Icon.Compass
    // },
    // {
    //     title: 'Web Security',
    //     desc: 'The phrasal sequence of the is now so that many campaign and benefit',
    //     Icon: Icon.Settings
    // },
    // {
    //     title: 'Web Security',
    //     desc: 'The phrasal sequence of the is now so that many campaign and benefit',
    //     Icon: Icon.Watch
    // },

]


export const project = [
    {
        slug: 'kemenag-cbt',
        image: '/images/works/kemenag-cbt/cover.png',
        client: 'Kemenag',
        title: 'CBT SNPDB & AKMI',
        role: 'Fullstack Developer',
        stacks: ['Express', 'React', 'Electron', 'Tailwind CSS', 'MySQL'],
        content: Cbt,
    },
    {
        slug: 'kemenag-report',
        image: '/images/works/kemenag-report/cover.jpg',
        client: 'Kemenag',
        title: 'CBT AKMI Report',
        role: 'Fullstack Developer',
        stacks: ['PHP', 'Express', 'Puppeteer', 'Tailwind CSS', 'MySQL'],
        content: CbtReport,
    },
    {
        slug: 'c-proc',
        image: '/images/works/c-proc/cover.png',
        client: 'Individual',
        title: 'C-Procurement',
        role: 'Frontend Developer',
        stacks: ['React', 'TypeScript', 'Material UI'],
        content: CProc,
    },
    {
        slug: 'sisi-procsi',
        image: '/images/works/sisi-procsi/cover.png',
        client: 'PT SISI',
        title: 'PROCSI',
        role: 'Frontend Developer',
        stacks: ['React', 'Bootstrap 4', 'Socket IO'],
        content: Procsi,
    },
    {
        slug: 'input-automation',
        image: '/images/works/input-automation/cover.png',
        client: 'Individual',
        title: 'Input Automation',
        role: 'Fullstack Developer',
        stacks: ['React', 'TypeScript', 'Electron', 'Tailwind CSS'],
        content: InputAutomation,
    },
    {
        slug: 'msi-questionnaire',
        image: '/images/works/msi-questionnaire/cover.png',
        client: 'PT Magnet Solusi Integra',
        title: 'Questionnaire',
        role: 'Web Developer',
        stacks: ['Laravel', 'Bootstrap 4', 'jQuery', 'MySQL'],
        content: MsiKuesioner,
    },
    {
        slug: 'um-sby-faculty',
        image: '/images/works/um-sby-faculty/cover.png',
        client: 'UM Surabaya',
        title: 'Faculty Website',
        role: 'Web Developer',
        stacks: ['Laravel', 'Bootstrap 4', 'jQuery', 'MySQL'],
        content: UmSbyFaculty,
    },
    {
        slug: 'um-sby-form',
        image: '/images/works/um-sby-form/cover.png',
        client: 'UM Surabaya',
        title: 'Form Website',
        role: 'Web Developer',
        stacks: ['Laravel', 'Bootstrap 4', 'jQuery', 'MySQL'],
        content: UmSbyForm,
    },
    {
        slug: 'bramindra-indotama',
        image: '/images/works/bramindra-indotama/cover.jpg',
        client: 'PT Bramindra Indotama',
        title: 'Company Profile',
        role: 'Web Developer',
        stacks: ['Laravel', 'Bootstrap 4', 'jQuery', 'MySQL'],
        content: BramindraIndotama,
    },
    {
        slug: 'myvote',
        image: '/images/works/myvote/cover.png',
        client: 'Personal',
        title: 'MyVote',
        role: 'Backend Developer & DevOps',
        stacks: ['Django', 'Django Rest Framework', 'PostgreSQL', 'Redis', 'GitLab CI/CD', 'Amazon Web Service'],
        content: MyVote,
    },
]

export const blogData = [
    {
        image: "/images/blog/01_giglink.jpg",
        title: 'Giglink: Tailwind CSS NFT Marketplace Template',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image: "/images/blog/01_techwind.jpg",
        title: 'Techwind: Tailwind CSS Multipurpose Template',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image: "/images/blog/01_upwind.jpg",
        title: 'Upwind: Tailwind CSS Landing Page Template',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
]
export const review = [
    {
        name: 'Thomas Israel',
        designation: 'Manager',
        image: '/images/client/01.jpg',
        desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development."
    },
    {
        name: 'Thomas Israel',
        designation: 'Manager',
        image: '/images/client/01.jpg',
        desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development."
    },
    {
        name: 'Thomas Israel',
        designation: 'Manager',
        image: '/images/client/01.jpg',
        desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development."
    },
    {
        name: 'Thomas Israel',
        designation: 'Manager',
        image: '/images/client/01.jpg',
        desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development."
    },
    {
        name: 'Thomas Israel',
        designation: 'Manager',
        image: '/images/client/01.jpg',
        desc: "Obviously I'm a Web Designer. Web Developer with over 7 years of experience. Experienced with all stages of the development."
    },
]

