import React from "react";
import { awardsData } from "../Data/data"
import ScrollAnimation from 'react-animate-on-scroll';

export default function Awards() {

    return (
        <section className="relative md:py-24 py-16" id="about">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true} delay={200}>
                        <h3 className="md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Awards</h3>
                    </ScrollAnimation>
                </div>

                <div className="flex flex-col gap-4 mx-auto">
                    {awardsData.map((item, index) => {
                        const Icon = item.Icon
                        return (
                            <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={300}>
                                <div className="flex flex-row w-full max-w-[800px] mx-auto gap-6 items-center px-6 py-4 shadow shadow-gray-200 hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900" key={index}>
                                    <Icon className="h-10 w-10 stroke-1 text-amber-500" />

                                    <div className="content">
                                        <h5 className="title text-[17px] font-medium hover:text-amber-500">{item.position}</h5>
                                        <h5 className="title text-[17px] hover:text-amber-500 mt-2">{item.title}</h5>
                                        <p className="text-slate-400 mt-2 text-[15px]">
                                            {item.year} · {item.instance}
                                        </p>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}