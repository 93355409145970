import React from 'react'
import { H3, H4, Image, P } from './_components'

const Procsi = () => {
    return (
        <>
            <H3>Overview</H3>

            <P>
                This project involved enhancing and customizing various modules of an existing procurement system, known as Procsi. Key features I worked on include the Tender module, which encompasses the Bidder List, Request for Quotation, Administrative Evaluation, Technical Evaluation, Commercial Evaluation, Awarding, and Purchase Order functionalities. Additionally, I contributed to refining the Master Data modules and developing the Expedition modules. Moreover, I played a pivotal role in developing the invoice module, which featured a Payment Order, Advance Payment Holder, Advance Payment Accountability, and a tiered verification process. My responsibilities also extended to conducting research and development on the E-Document module, focusing on real-time document editing and collaborative commenting features.
            </P>

            <H3>Tech Stack</H3>

            <H4>React</H4>
            <P>ReactJS was the primary technology used in developing the front-end of these modules, providing a dynamic and responsive user interface that enhanced user interaction and experience.</P>

            <H4>Bootstrap 4</H4>
            <P>Bootstrap 4 played a significant role in styling and ensuring the responsive design of the application, facilitating a seamless and intuitive user interface.</P>

            <H4>Socket IO</H4>
            <P>For the E-Document module, Socket IO was utilized to enable real-time communication features, allowing multiple users to edit documents and comment simultaneously in a collaborative environment.</P>

            <H3>Visual</H3>

            <H4>Login Page</H4>
            <P>The redesigned login page provides a secure and user-friendly entry point into the system.</P>
            <Image
                src="/images/works/sisi-procsi/1-login.png"
                alt="Login Page"
            />

            <H4>Project Evaluation Budget</H4>
            <P>This feature allows for a detailed overview and management of project budgets.</P>
            <Image
                src="/images/works/sisi-procsi/2-peb.png"
                alt="Project Evaluation Budget"
            />

            <H4>Purchasing Requisition</H4>
            <P>Streamlines the process of creating and managing purchasing requisitions.</P>
            <Image
                src="/images/works/sisi-procsi/3-pr.png"
                alt="Purchasing Requisition"
            />

            <H4>Bidder List</H4>
            <P>Facilitates the management and evaluation of bidders in the procurement process.</P>
            <Image
                src="/images/works/sisi-procsi/4-bidderlist.png"
                alt="Bidder List"
            />

            <H4>Purchase Order</H4>
            <P>Enhances the creation and tracking of purchase orders within the system.</P>
            <Image
                src="/images/works/sisi-procsi/5-po.png"
                alt="Purchase Order"
            />
        </>
    )
}

export default Procsi