import React from 'react'
import { H3, H4, Image, P } from './_components'

const UmSbyFaculty = () => {
    return (
        <>
            <H3>Overview</H3>

            <P>This project involved developing a Content Management System (CMS) intended for use across various faculties at the University of Muhammadiyah Surabaya (UM Surabaya). The CMS was designed to streamline content management on faculty websites, enabling easy updates and modifications to various sections such as the home page, profile, academics, announcements, and detailed program studies.</P>

            <H3>Tech Stack</H3>

            <H4>Laravel</H4>
            <P>Chosen for its robustness and flexibility, Laravel was used to develop the CMS's back-end. It provided a strong foundation for managing database interactions and server-side logic.</P>

            <H4>Bootstrap 4</H4>
            <P>This front-end framework was instrumental in designing a responsive and user-friendly interface for the CMS, ensuring accessibility and ease of use.</P>

            <H4>jQuery</H4>
            <P>Incorporated to enhance the interactivity of the CMS, jQuery enabled dynamic content manipulation and improved user experience.</P>

            <H4>MySQL</H4>
            <P>Selected as the database solution, MySQL handled the storage and retrieval of all content-related data, ensuring data integrity and efficient querying.</P>

            <H3>Visual</H3>

            <H4>Landing Page</H4>
            <P>Showcases the custom-built landing page whose content is managed through the admin dashboard, highlighting the CMS's flexibility.</P>
            <Image
                src="/images/works/um-sby-faculty/1-landing-page.png"
                alt="Landing Page"
            />

            <H4>News</H4>
            <P>This allows for the publication of news or articles, starting from author creation, through reviewer verification, to admin publishing.</P>
            <Image
                src="/images/works/um-sby-faculty/2-news.png"
                alt="News"
            />

            <H4>Study Program</H4>
            <P>A crucial feature enabling the management of study program information specific to each faculty, with designated administrators for each program.</P>
            <Image
                src="/images/works/um-sby-faculty/3-study-program.png"
                alt="Study Program"
            />
        </>
    )
}

export default UmSbyFaculty