import React from 'react'
import { H3, H4, Image, P } from './_components'

const InputAutomation = () => {
    return (
        <>
            <H3>Overview</H3>

            <P>Input Automation is a sophisticated application designed to automate the data entry process. Its primary goal is to replace manual, one-by-one data typing, a task traditionally time-consuming and prone to errors. By leveraging this application, data input becomes significantly faster and more efficient, streamlining the entire data entry workflow.</P>

            <H3>Tech Stack</H3>

            <H4>React</H4>
            <P>Utilized for building a dynamic and responsive user interface, React allows for a smooth and interactive experience in the application.</P>

            <H4>TypeScript</H4>
            <P>TypeScript adds a layer of reliability and maintainability to the codebase, ensuring type safety and enhanced development efficiency.</P>

            <H4>Electron</H4>
            <P>Electron is used to create a standalone desktop application from the web-based interface, allowing for greater accessibility and usability.</P>

            <H4>Tailwind CSS</H4>
            <P>Employed for its utility-first approach, Tailwind CSS ensures that the application is both visually appealing and intuitively designed.</P>

            <H3>Visual</H3>

            <H4>Setting</H4>
            <P>Allows users to configure target URLs for automation, input patterns, button patterns, and execution scripts.</P>
            <Image
                src="/images/works/input-automation/1-setting.png"
                alt="Settings"
            />

            <H4>Import Customer</H4>
            <P>Enables the import of customer data from CSV files.</P>
            <Image
                src="/images/works/input-automation/2-import-customer.png"
                alt="Import Customer"
            />

            <H4>Home Page</H4>
            <P>Displays a list of customers and their status, indicating whether the customer has been processed or not.</P>
            <Image
                src="/images/works/input-automation/3-ready.png"
                alt="Home Page"
            />

            <H4>Fill Page</H4>
            <P>Contains a list of customers filtered by specific groups. This page also features a "Fill" button to execute the automation script.</P>
            <Image
                src="/images/works/input-automation/4-fill.png"
                alt="Fill Page"
            />

            <H3>Demo</H3>
            <P>The demo video illustrates the application's functionality in real time, showcasing how users can interact with different features for efficient data input and management. </P>
            <video className="w-full" controls>
                <source src="/images/works/input-automation/demo.mp4" type="video/mp4" />
            </video>
        </>
    )
}

export default InputAutomation