import React, { useMemo, useState } from "react";
// import '../../node_modules/react-18-image-lightbox/style.css';
import { project } from "../Data/data";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import * as Icon from 'react-feather';
import ScrollAnimation from 'react-animate-on-scroll';
import { useSearchParams } from "react-router-dom";

export default function Projects() {
    const [searchParams, setSearchParams] = useSearchParams({ project: '' });

    const defaultProject = useMemo(() => {
        const queryProject = searchParams.get('project');

        if (queryProject) {
            const index = project.findIndex(item => item.slug === queryProject);
            if (index !== -1) {
                return project[index];
            }
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const [photoIndex, setActiveIndex] = useState(0);
    const [isOpen, setOpen] = useState(!!defaultProject);
    const [data, setData] = useState(defaultProject);

    const handleClick = (index) => {
        setOpen(true);
        setData(project[index]);

        setSearchParams({ project: project[index].slug });
    }

    const handleClose = () => {
        setOpen(false);
        setData(null);

        setSearchParams({ project: '' });
    }

    return (
        <>
            <section className="relative md:py-24 py-16" id="project">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true} delay={300}>
                            <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">My Work & Projects</h3>
                        </ScrollAnimation>

                        <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={300}>
                            <p className="text-slate-400 max-w-xl mx-auto text-[15px]">A curated collection of my diverse and innovative web development ventures, highlighting my technical prowess and creative approach.</p>
                        </ScrollAnimation>
                    </div>

                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        {project.map((item, index) => (
                            <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={(index + 1) * 50 + 300}>
                                <div key={item.slug} className="h-full shadow shadow-gray-200 hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900">
                                    <div className="py-20 w-full relative bg-no-repeat bg-center bg-cover rounded-t-2xl" style={{ backgroundImage: `url(${item.image})` }}>
                                        <div className="absolute inset-0 bg-black bg-opacity-25 rounded-t-2xl"></div>
                                    </div>
                                    <div className="px-6 py-6">
                                        <div className="content flex flex-col gap-2">
                                            <h5 onClick={() => handleClick(index)} className="title text-[17px] font-medium hover:text-amber-500 cursor-pointer">
                                                {item.title}
                                            </h5>
                                            <p className="text-slate-400 text-[15px]">
                                                {item.role}
                                                <span> · </span>
                                                {item.client}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        ))}
                    </div>
                </div>
            </section>
            {/* {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setOpen(false)}
                    onMovePrevRequest={() =>
                        setActiveIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() =>
                        setActiveIndex((photoIndex + 1) % images.length)
                    }
                />
            )} */}

            <DialogCustom open={isOpen} data={data} onClose={handleClose} />
        </>

    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DialogCustom = ({ open, data, onClose, }) => {
    const Content = data?.content

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
        >
            <div className="bg-white dark:bg-slate-900">
                {open && data && (
                    <div className="w-full max-w-3xl mx-auto">
                        <div className="flex justify-end py-3">
                            <button
                                type="button"
                                className="p-5 bg-red-500/10 hover:bg-red-500 border-red-500/10 hover:border-red-500 text-red-500 hover:text-white rounded-md ms-1"
                                onClick={onClose}
                            >
                                <Icon.X />
                            </button>
                        </div>

                        <hr />

                        <div className="pb-64">
                            <div className="py-36 w-full relative bg-no-repeat bg-center bg-cover" style={{ backgroundImage: `url(${data.image})` }}>
                                <div className="absolute inset-0 bg-black bg-opacity-10"></div>
                            </div>

                            <h1 className="font-bold lg:text-[40px] text-3xl lg:leading-normal leading-normal text-slate-900 dark:text-slate-200 mb-4 mt-4">{data.title}</h1>

                            <hr className="my-3" />

                            <div className="grid md:grid-cols-3 grid-cols-1 gap-[30px]">
                                <div>
                                    <h5 className="text-md text-slate-500 mb-1">Role</h5>
                                    <p className="font-semibold text-[15px] text-slate-900 dark:text-slate-200">
                                        {data.role}
                                    </p>
                                </div>
                                <div>
                                    <h5 className="text-md text-slate-500 mb-1">Client</h5>
                                    <p className="font-semibold text-[15px] text-slate-900 dark:text-slate-200">
                                        {data.client}
                                    </p>
                                </div>
                                <div>
                                    <h5 className="text-md text-slate-500 mb-1">Stack</h5>
                                    <p className="font-semibold text-[15px] text-slate-900 dark:text-slate-200">
                                        {data.stacks.join(', ')}
                                    </p>
                                </div>
                            </div>

                            <hr className="my-3" />

                            <div style={{
                                fontFamily: 'Source Sans Pro, Georgia, Cambria, "Times New Roman", Times, serif',
                            }}>
                                <Content />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Dialog >
    )
}
