import React from 'react'
import { H3, H4, Image, P } from './_components'

const CbtReport = () => {
    return (
        <>
            <H3>Overview</H3>

            <P>This project involved creating digital reports in HTML format, later converted to PDFs, for displaying the assessment results of students in various Islamic schools under the Ministry of Religious Affairs / Kementrian Agama Republic Indonesia (Kemenag). The reports were categorized into four types: individual student reports, school-wide reports, district/city reports, and provincial reports. A notable feature of this project was the development of a service for batch downloading these reports, allowing automatic download of all student reports for each school, thereby streamlining the process.</P>

            <H3>Tech Stack</H3>

            <H4>PHP</H4>
            <P>Used as the primary language for retrieving data from the database and displaying it in an HTML format. PHP was crucial for generating dynamic and customized content for each type of report.</P>

            <H4>Express</H4>
            <P>This Node.js framework was used to create endpoints for batch downloading the reports. It allowed for the compilation of individual PDFs into ZIP files, simplifying the distribution process.</P>

            <H4>Puppeteer</H4>
            <P>A key tool in converting the PHP-rendered HTML pages into high-quality PDF files. Puppeteer ensured efficient load management on the server, even under high demand.</P>

            <H4>Tailwind CSS</H4>
            <P>Employed for its utility-first approach to design, Tailwind CSS was instrumental in crafting responsive and visually appealing reports.</P>

            <H4>MySQL</H4>
            <P>Acted as the database system, storing all necessary data for report generation. Both PHP and Express fetched and processed data from MySQL, ensuring accurate and up-to-date information in the reports.</P>

            <H3>Kind of Reports</H3>

            <H4>1. Student Report</H4>
            <P>Displays the assessment results of individual students, differentiated by educational levels (primary, middle, and high school), with unique indicators for each.</P>
            <Image
                src="/images/works/kemenag-report/1-siswa.jpg"
                alt="Student Report"
            />

            <H4>2. School Report</H4>
            <P>Shows the collective assessment results of a school, also categorized by educational levels. </P>
            <Image
                src="/images/works/kemenag-report/2-madrasah.jpg"
                alt="School Report"
            />

            <H4>3. District / City Report</H4>
            <P>Summarizes assessment results at the district or city level, providing a broader view of educational performance. </P>
            <Image
                src="/images/works/kemenag-report/2-madrasah.jpg"
                alt="District / City Report"
            />

            <H4>4. Province Report</H4>
            <P>Offers a comprehensive overview of the assessment results at the provincial level.</P>
            <Image
                src="/images/works/kemenag-report/2-madrasah.jpg"
                alt="Province Report"
            />
        </>
    )
}

export default CbtReport