import React from "react";
import { Link } from "react-scroll";
import ScrollAnimation from 'react-animate-on-scroll';

export default function AddBanner() {
    return (
        <section className="py-20 w-full table relative bg-[url('../../assets/images/bg/bg2.jpg')] bg-no-repeat bg-center bg-cover">
            <div className="absolute inset-0 bg-slate-900/70"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 text-center">
                    <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true} delay={500}>
                        <h3 className="mb-4 md:text-2xl text-xl text-white font-semibold">Open for Collaboration and Opportunities</h3>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={600}>
                        <p className="text-white/80 max-w-xl mx-auto text-[15px]">Passionate and versatile in web development, I am eager to explore both freelance projects and full-time roles, bringing innovative solutions and technical expertise to your project/team.</p>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={700}>
                        <div className="relative mt-8">
                            <Link to="contact" className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white rounded-md">Hire Me</Link>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </section>
    )
}