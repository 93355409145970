import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

export default function Experience() {
    return (
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="experience">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true} delay={300}>
                        <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Work Experience</h3>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={300}>
                        <p className="text-slate-400 max-w-xl mx-auto text-[15px]">Showcasing a rich tapestry of roles, my work experience encompasses full-stack web development across different sectors, blending technical innovation with practical solutions, from advanced frontend technologies to backend systems integration.</p>
                    </ScrollAnimation>
                </div>

                <div className="grid grid-cols-1 mt-8">
                    <div className="relative after:content-[''] after:absolute after:top-0 ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">

                        <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <img src="https://sisi.id/wp-content/uploads/2019/07/Logo-SISI-new-1-300x153-1.png" className="rounded-full h-9 w-auto md:ms-auto" alt="" />
                                        <h5 className="my-2 font-semibold text-lg">PT Sinergi Informatika Semen Indonesia</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <h6 className="text-sm mb-2">January 2022 - October 2023</h6>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <h6 className="text-sm mb-0 font-semibold">Full-time Remote</h6>
                                    </ScrollAnimation>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h5 className="title mb-1 font-semibold">E-Procurement as Frontend Developer</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="text-slate-400 text-sm mb-1 font-semibold">January 2023 - October 2023  · 10 mos</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="mt-3 mb-0 text-slate-400 text-[15px]">Customize the Tender module from the existing system. The module includes Bidder List, Request for Quotation, Administrative Evaluation, Technical Evaluation, Commercial Evaluation, Awarding and Purchase Order features. In addition, it also helps customize Master Data modules and helps to create Expedition modules. The technologies used in this project are ReactJS and Bootstrap 4.</p>
                                    </ScrollAnimation>

                                    <hr className="my-4 border-gray-200 dark:border-gray-700" />

                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h5 className="title mb-1 font-semibold">E-Invoice as Web Developer</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="text-slate-400 text-sm mb-1 font-semibold">November 2022 - December 2022 · 2 mos</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="mt-3 mb-0 text-slate-400 text-[15px]">Integrate the Semen Indonesia Group's internal E-Invoice website with PERURI to implement the E-Sign and E-State Stamp features and help adjust the features in them. The technologies used in this project are PHP Native and Oracle.</p>
                                    </ScrollAnimation>

                                    <hr className="my-4 border-gray-200 dark:border-gray-700" />

                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h5 className="title mb-1 font-semibold">Quality Management as Web Developer</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="text-slate-400 text-sm mb-1 font-semibold">July 2022 - October 2022 · 3 mos</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="mt-3 mb-0 text-slate-400 text-[15px]">Handling the development of Master Data, Non-Conformance Quality Report (NCQR) modules and assisting the development of other modules. The technologies used in this project are CodeIgniter 3, Bootstrap 3, and Oracle.</p>
                                    </ScrollAnimation>

                                    <hr className="my-4 border-gray-200 dark:border-gray-700" />

                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h5 className="title mb-1 font-semibold">E-Procurement as Frontend Developer</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="text-slate-400 text-sm mb-1 font-semibold">January 2022 - July 2022 · 7 mos</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="mt-3 mb-0 text-slate-400 text-[15px]">Handling the development of the invoice module which includes a Payment Order, Advance Payment Holder and Advance Payment Accountability, besides that there is also a tiered verification flow. The technologies used in this project are ReactJS and Bootstrap 4.</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="mt-3 mb-0 text-slate-400 text-[15px]">Entrusted to carry out RnD on the E-Document module which includes features for editing document contents and providing comments by several people in real time. The technologies used in this RnD project are NodeJS and Web Sockets.</p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>

                        <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900 mt-8">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <img src="https://www.um-surabaya.ac.id/uploads/home/about/makna_lambang/foto_rektor-admin-Nq3kFA.webp" className="rounded-full h-9 w-auto md:ms-auto" alt="" />
                                        <h5 className="my-2 font-semibold text-lg">Universitas Muhammadiyah Surabaya</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <h6 className="text-sm mb-2">January 2022 - February 2022</h6>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <h6 className="text-sm mb-0 font-semibold">Internship</h6>
                                    </ScrollAnimation>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h5 className="title mb-1 font-semibold">Faculy Website as Web Developer</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="text-slate-400 text-sm mb-1 font-semibold">January 2022</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="mt-3 mb-0 text-slate-400 text-[15px]">Create a Faculty Web that is used to display and manage faculty profiles dynamically. The technology used is Laravel, Bootstrap 4, TailwindCSS.</p>
                                    </ScrollAnimation>

                                    <hr className="my-4 border-gray-200 dark:border-gray-700" />

                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h5 className="title mb-1 font-semibold">Form Website as Web Developer</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="text-slate-400 text-sm mb-1 font-semibold">February 2022</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="mt-3 mb-0 text-slate-400 text-[15px]">Create a Web Form which in the future will be used to create registration forms, webinar attendance forms, agenda attendance forms and so on. The technology used is Laravel, Bootstrap 4, TailwindCSS.</p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>

                        <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900 mt-8">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <img src="/images/logos/gcs.png" className="rounded-full h-9 w-auto md:ms-auto" alt="" />
                                        <h5 className="my-2 font-semibold text-lg">PT Gresik Cipta Sejahtera</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <h6 className="text-sm mb-2">January 2018 - March 2018</h6>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <h6 className="text-sm mb-0 font-semibold">Internship</h6>
                                    </ScrollAnimation>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h5 className="title mb-1 font-semibold">Letter Archive Information System as Web Developer</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="text-slate-400 text-sm mb-1 font-semibold">January 2018 - March 2018 · 3 mos</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="mt-3 mb-0 text-slate-400 text-[15px]">In the first month, assigned to the project of creating an archive letter information system using PHP Native, Bootstrap 3, and MySQL.</p>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <p className="mt-3 mb-0 text-slate-400 text-[15px]">During the following two months, assigned to the project of converting the archive letter information system that previously used PHP Native, and then integrated with the Laravel framework.</p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}