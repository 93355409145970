import React from "react";
import { servicesData } from "../Data/data"
import ScrollAnimation from 'react-animate-on-scroll';

export default function Services() {
    return (
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="service">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true} delay={300}>
                        <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">What do i offer?</h3>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={300}>
                        <p className="text-slate-400 max-w-xl mx-auto text-[15px]">Offering cutting-edge web solutions, I specialize in creating responsive, user-friendly applications and adeptly manage server-side configurations and deployments.</p>
                    </ScrollAnimation>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    {servicesData.map((item, index) => {
                        const Icon = item.Icon
                        return (
                            <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={((index) % 3 + 1) * 100 + 300}>
                                <div className="h-full px-6 py-10 shadow shadow-gray-200 hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900" key={index}>
                                    <Icon className="h-10 w-10 stroke-1 text-amber-500" />

                                    <div className="content mt-7">
                                        <h5 className="title text-[17px] font-medium">{item.title}</h5>
                                        <p className="text-slate-400 mt-3 text-[15px]">{item.desc}</p>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        )
                    })}

                </div>
            </div>
        </section>
    )
}