import React from 'react'
import { H3, H4, Image, P } from './_components'

const BramindraIndotama = () => {
    return (
        <>
            <H3>Overview</H3>

            <P>This project entailed developing a company profile website for Bramindra Indotama, a company operating in the electrical, mechanical, and civil sectors. The website was designed to effectively showcase the company's services and expertise. A key feature of this site is its content management system, which allows the admin to update and manage the website content dynamically via an admin panel.</P>

            <H3>Tech Stack</H3>

            <H4>Laravel</H4>
            <P>Leveraged for its robust backend capabilities, Laravel played a crucial role in managing the website's content and user interactions efficiently.</P>

            <H4>Bootstrap 4</H4>
            <P>This front-end framework was chosen to ensure the website was responsive and accessible, providing a seamless user experience across various devices.</P>

            <H4>jQuery</H4>
            <P>Used to enhance the website's interactivity, jQuery facilitated dynamic content updates and user-friendly navigation.</P>

            <H4>MySQL</H4>
            <P>As the database system, MySQL was pivotal in storing and managing the website's data, ensuring reliable and efficient data handling.</P>

            <H3>Visual</H3>

            <H4>Home</H4>
            <P>Offers a welcoming and informative introduction to the company, highlighting key services and strengths.</P>
            <Image
                src="/images/works/bramindra-indotama/1-home.jpg"
                alt="Home"
            />

            <H4>Service</H4>
            <P>Details the range of services offered by Bramindra Indotama, showcasing their expertise in each sector.</P>
            <Image
                src="/images/works/bramindra-indotama/2-service.jpg"
                alt="Service"
            />

            <H4>Documentation</H4>
            <P>Provides access to important company documents and resources for clients and partners.</P>
            <Image
                src="/images/works/bramindra-indotama/3-documentation.jpg"
                alt="Documentation"
            />

            <H4>Legality</H4>
            <P>Displays legal and compliance-related information, reinforcing the company's credibility.</P>
            <Image
                src="/images/works/bramindra-indotama/4-legality.jpg"
                alt="Legality"
            />

            <H4>Contact Us</H4>
            <P>Facilitates easy communication with the company through a user-friendly contact form.</P>
            <Image
                src="/images/works/bramindra-indotama/5-contact-us.jpg"
                alt="Contact Us"
            />

            <H4>About Us</H4>
            <P>Offers an in-depth look at the company's history, mission, and values.</P>
            <Image
                src="/images/works/bramindra-indotama/6-about-us.jpg"
                alt="About Us"
            />
        </>
    )
}

export default BramindraIndotama