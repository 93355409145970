import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

export default function Education() {
    const [showMore, setShowMore] = React.useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    }

    return (
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="experience">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true} delay={300}>
                        <h3 className="md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Education</h3>
                    </ScrollAnimation>
                </div>

                <div className="grid grid-cols-1 mt-8">
                    <div className="relative after:content-[''] after:absolute after:top-0 ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 after:w-px after:h-full md:after:m-auto after:border-s-2 after:border-dashed after:border-gray-200 dark:after:border-gray-700 ms-3 md:ms-0">

                        <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <h6>2019 - 2023</h6>
                                    </ScrollAnimation>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h5 className="mb-2 font-semibold text-lg">Universitas Islam Negeri Sunan Ampel Surabaya</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h6 className="text-sm mb-0">Bachelor's Degree, Information Systems</h6>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>

                        <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900 mt-8">
                            <div className="grid md:grid-cols-2">
                                <div className="md:text-end md:me-8 relative">
                                    <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                        <h6>2016 - 2019</h6>
                                    </ScrollAnimation>
                                </div>

                                <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h5 className="mb-2 font-semibold text-lg">SMK Semen Gresik</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <h6 className="text-sm mb-0">High School Student, Computer Software Engineer</h6>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>

                        {showMore && (
                            <>
                                <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900 mt-8">
                                    <div className="grid md:grid-cols-2">
                                        <div className="md:text-end md:me-8 relative">
                                            <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={100}>
                                                <h6>2013 - 2016</h6>
                                            </ScrollAnimation>
                                        </div>

                                        <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                            <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={100}>
                                                <h5 className="mb-2 font-semibold text-lg">SMP NU 1 Gresik</h5>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={150}>
                                                <h6 className="text-sm mb-0">Junior High School</h6>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>

                                <div className="ms-8 md:ms-0 relative after:content-[''] after:absolute after:top-[9px] after:rounded-full after:z-10 after:w-2.5 after:h-2.5 after:bg-amber-500 md:after:mx-auto ltr:md:after:right-0 ltr:md:after:left-0 rtl:md:after:left-0 rtl:md:after:right-0 ltr:after:-left-9 rtl:after:-right-9 before:content-[''] before:absolute md:before:mx-auto ltr:md:before:right-0 ltr:md:before:left-0 ltr:before:-left-11 rtl:md:before:left-0 rtl:md:before:right-0 rtl:before:-right-11 before:rounded-full before:z-10 before:border-2 before:border-dashed before:border-gray-200 dark:before:border-gray-700 before:top-0 before:w-7 before:h-7 before:bg-white dark:before:bg-slate-900 mt-8">
                                    <div className="grid md:grid-cols-2">
                                        <div className="md:text-end md:me-8 relative">
                                            <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={200}>
                                                <h6>2007 - 2013</h6>
                                            </ScrollAnimation>
                                        </div>

                                        <div className="ltr:float-left rtl:float-right text-start md:ms-8 mt-6 md:mt-0">
                                            <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={200}>
                                                <h5 className="mb-2 font-semibold text-lg">MINU Trate Putra Gresik</h5>
                                            </ScrollAnimation>
                                            <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={250}>
                                                <h6 className="text-sm mb-0">Elementary School</h6>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="text-center mt-4">
                    <button type="button" onClick={toggleShowMore} className="btn bg-amber-500/10 hover:bg-amber-500 border-amber-500/10 hover:border-amber-500 text-amber-500 hover:text-white rounded-md mt-2">
                        {showMore ? 'Show Less' : 'Show More'}
                    </button>
                </div>
            </div>
        </section>
    )
}