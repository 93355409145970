import React from 'react'
import { H3, H4, H5, Image, P } from './_components'

const MyVote = () => {
    return (
        <>
            <H3>Overview</H3>

            <P>MyVote.id is a sophisticated online platform designed for conducting election voting. Gaining significant trust and recognition, it was utilized for the election of student association leaders at a prominent campus in 2020 and 2021. This platform stands out for its reliability, security, and user-friendly interface, catering specifically to the dynamic needs of modern electoral processes.</P>
            <P>As a Backend Developer on this project, my role involved building and maintaining a robust backend architecture that ensures seamless, secure, and efficient voting experiences.</P>

            <H3>Backend Tech Stack</H3>

            <H4>Django</H4>
            <P>As the primary web framework, Django was instrumental in constructing a stable and scalable backend. Its robustness and versatility made it an ideal choice for handling complex electoral processes..</P>

            <H4>Django Rest Framework</H4>
            <P>Utilized for creating a clean, well-structured API layer. This framework facilitated easy communication between the front-end and back-end, ensuring smooth data flow and integration.</P>

            <H4>PostgreSQL</H4>
            <P>Chosen for its reliability and performance, PostgreSQL served as the primary database, handling vast amounts of election data securely and efficiently.</P>

            <H4>Redis</H4>
            <P>Acted as a message broker, Redis significantly enhanced the platform's performance by handling asynchronous tasks and caching, ensuring fast and responsive user interactions.</P>

            <H4>Swagger</H4>
            <P>Employed for API documentation, Swagger made it easier to understand, use, and integrate the platform's APIs, enhancing developer experience and collaboration.</P>

            <H3>DevOps Tech Stack</H3>

            <H4>Gitlab CI/CD</H4>
            <P>Implemented for automated testing and deployment. This tool streamlined the development process, ensuring quick and reliable updates and deployment of new features.</P>

            <H4>AWS Services</H4>

            <H5>S3 (Simple Storage Service)</H5>
            <P>For secure and scalable cloud storage solutions.</P>

            <H5>EC2 (Elastic Compute Cloud)</H5>
            <P>Provided reliable and scalable computing capacity, ensuring the platform could handle high traffic during elections.</P>

            <H5>Route 53</H5>
            <P>Managed DNS services, ensuring a robust and reliable connection to the platform.</P>

            <H3>Visual</H3>

            <H4>Landing Page</H4>
            <P>The landing page serves as the welcoming interface for voters, featuring a clean and intuitive design that guides users seamlessly into the voting process.</P>
            <Image
                src="/images/works/myvote/1-landing.png"
                alt="Landing Page"
            />

            <H4>Input Voting Token</H4>
            <P>This crucial feature allows voters to securely enter their unique voting token, ensuring that the voting process remains exclusive and protected.</P>
            <Image
                src="/images/works/myvote/2-input-token.png"
                alt="Input Voting Token"
            />

            <H4>Voting Page</H4>
            <P>The heart of the platform, the voting page, is where voters make their choices. It's designed for clarity and ease of use, displaying candidates and options in an organized manner.</P>
            <Image
                src="/images/works/myvote/3-voting-page.png"
                alt="Voting Page"
            />

            <H4>Candidate's Vision and Mission</H4>
            <P>Each candidate's vision and mission are presented in a structured format on this page, allowing voters to make informed decisions based on the candidates' objectives and plans</P>
            <Image
                src="/images/works/myvote/4-vision-mission.png"
                alt="Candidate's Vision and Mission"
            />

            <H4>Feedback</H4>
            <P>Post-voting, users are directed to the feedback page where they can share their experience and suggestions, contributing to the continuous improvement of the platform.</P>
            <Image
                src="/images/works/myvote/5-feedback.png"
                alt="Feedback"
            />

            <H4>Report / Leaderboard</H4>
            <P>These pages are critical for transparency and engagement. The report page provides detailed voting results, while the leaderboard displays real-time rankings of candidates based on votes received.</P>
            <Image
                src="/images/works/myvote/6-report.png"
                alt="Report"
            />
            <Image
                src="/images/works/myvote/7-leaderboard.png"
                alt="Leaderboard"
            />
        </>
    )
}

export default MyVote