import React from 'react'
import { H3, H4, Image, P } from './_components'

const LinkReactNestable = () => (
    <a
        href="https://www.npmjs.com/package/react-nestable"
        target="_blank"
        rel="noopener noreferrer"
        className="text-amber-500 hover:text-amber-600"
    >
        react-nestable
    </a>
)

const LinkDndKit = () => (
    <a
        href="https://dndkit.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-amber-500 hover:text-amber-600"
    >
        @dnd-kit/core
    </a>
)

const LinkReactOrgChart = () => (
    <a
        href="https://www.npmjs.com/package/react-organizational-chart"
        target="_blank"
        rel="noopener noreferrer"
        className="text-amber-500 hover:text-amber-600"
    >
        react-organizational-chart
    </a>
)

const CProc = () => {
    return (
        <>
            <H3>Overview</H3>

            <P>C-Proc, short for C-Procurement, is an innovative application designed to streamline the procurement process of goods and services within a company. This application serves as a comprehensive solution for managing procurement activities, from initial request to final acquisition, enhancing efficiency and transparency in corporate procurement operations.</P>

            <H3>Tech Stack</H3>

            <H4>React</H4>
            <P>The core of C-Proc is built using React, providing a robust and dynamic user interface. This allows for interactive and responsive interactions within the application, ensuring a smooth user experience.</P>

            <H4>TypeScript</H4>
            <P>TypeScript adds a layer of reliability and structure to the application. By leveraging TypeScript's strong typing system, the codebase becomes more maintainable and less prone to runtime errors, boosting overall development efficiency.</P>

            <H4>Material UI</H4>
            <P>Material UI is used as the primary UI library, giving C-Proc a sleek and modern look. It helps in creating a consistent and intuitive user interface, aligning with the latest design standards.</P>

            <H4>Drag and Drop Library</H4>
            <P>For advanced functionalities like stack data management and organizational chart creation, C-Proc integrates specialized libraries such as <LinkReactNestable /> for drag-and-drop stack data and <LinkDndKit /> with <LinkReactOrgChart /> for creating and managing organizational charts. These features enhance the application's interactivity and user engagement.</P>

            <H3>Visual</H3>

            <H4>Master Data Page</H4>
            <P>The Master Data page, pivotal in managing core data, is designed for ease of use and clarity.</P>
            <Image src="/images/works/c-proc/1-company.png" alt="Master Data Page" />

            <H4>Drag and Drop Stack Data</H4>
            <P>This feature, powered by <LinkReactNestable />, enables intuitive manipulation of hierarchical data, making complex data structures manageable and visually accessible.</P>
            <Image src="/images/works/c-proc/2-org-unit.png" alt="Stack Data" />

            <H4>Drag and Drop Organizational Chart</H4>
            <P>Utilizing <LinkDndKit /> and <LinkReactOrgChart />, this functionality allows users to effortlessly construct and modify organizational charts, providing a clear visual representation of company structures. </P>
            <Image src="/images/works/c-proc/3-org-unit-chart.png" alt="Organizational Chart" />
            

            <H3>Drag and Drop Demo</H3>

            <H4>Stack Data</H4>
            <video className="w-full" controls>
                <source src="/images/works/c-proc/dnd-menu.mp4" type="video/mp4" />
            </video>

            <H4>Organizational Chart</H4>
            <video className="w-full" controls>
                <source src="/images/works/c-proc/dnd-chart.mp4" type="video/mp4" />
            </video>
        </>
    )
}

export default CProc