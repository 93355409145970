import React from 'react'
import { H3, H4, Image, P } from './_components'

const Cbt = () => {
    return (
        <>
            <H3>Overview</H3>

            <P>Creating a Computer Based Test (CBT) website for the National Selection of New Students (SNPDB) at the Insan Cendikia State Madrasah Aliyah (MAN-IC), the State Madrasah Aliyah Religious Program (MAN-PK) and the State Vocational Madrasah Aliyah (MAKN). This website is used to conduct online exams for prospective students who want to register at their favorite school. The scale of this project is national, because the schools that use this website are spread throughout Indonesia.</P>

            <P>Apart from that, the CBT website is also used for the Indonesian Madrasah Competency Assessment (AKMI) which is organized by the Ministry of Religion (Kemenag). AKMI is an evaluation program for elementary to upper secondary madrasah students organized by the Ministry of Religion (Kemenag). This website is used to carry out assessment tests for madrasa students conducted by the Ministry of Religion. The scale of this project is national, because the schools that use this website are spread throughout Indonesia.</P>

            <H3>Tech Stack</H3>

            <H4>Express</H4>
            <P>For this project, Express played a crucial role in building robust APIs. These APIs are consumed by the frontend of the CBT application, ensuring seamless data handling and server-side logic implementation.</P>

            <H4>React</H4>
            <P>Leveraging React, the frontend of the CBT platform was developed. Its component-based architecture allowed for building a dynamic and interactive user interface, enhancing the online test-taking experience with efficient rendering and state management.</P>

            <H4>Electron</H4>
            <P>In this project, Electron was a pivotal technology, transforming the web-based CBT platform into a secure and focused standalone desktop application. Its integration played a critical role in enforcing exam integrity. By utilizing Electron, we were able to implement functionalities that prevent test-takers from switching to other applications during an exam, thus ensuring a controlled and cheat-proof environment. This feature was especially crucial for maintaining the credibility and fairness of the national-level testing process.</P>

            <H4>Tailwind CSS</H4>
            <P>Tailwind CSS was utilized for its utility-first approach, enabling rapid and responsive design. This facilitated the creation of a visually appealing and user-friendly interface, ensuring accessibility and ease of use for students across Indonesia.</P>

            <H4>MySQL</H4>
            <P>MySQL served as the backbone for data storage. It managed vast amounts of test data, user information, and exam records, providing a reliable and scalable database solution for the extensive needs of the national-level CBT system.</P>

            <H3>Visual</H3>

            <H4>Login Page</H4>
            <Image src="/images/works/kemenag-cbt/1-login.png" alt="Login Page" />

            <H4>Home Page</H4>
            <Image src="/images/works/kemenag-cbt/2-home.png" alt="Home Page" />

            <H4>Multiple Choice</H4>
            <Image src="/images/works/kemenag-cbt/3-multiple-choice.png" alt="Multiple Choice" />

            <H4>Checkbox</H4>
            <Image src="/images/works/kemenag-cbt/4-checkbox.png" alt="Checkbox" />

            <H4>Matching</H4>
            <Image src="/images/works/kemenag-cbt/5-match.png" alt="Matching" />

            <H4>Matching Unrepeatable</H4>
            <Image src="/images/works/kemenag-cbt/6-match-unrepeatable.png" alt="Matching Unrepeatable" />
        </>
    )
}

export default Cbt