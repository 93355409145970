import React from "react";
import { expertiseData } from "../Data/data"
import CountUp from 'react-countup';
import { Link } from "react-scroll";
import ScrollAnimation from 'react-animate-on-scroll';

export default function AboutUs() {

    return (
        <section className="relative md:py-24 py-16" id="about">
            <div className="container">
                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                    <div className="lg:col-span-5 lg:px-8">
                        <div className="relative">
                            <div className="absolute inset-0 border dark:border-gray-800 rounded-full -mt-[10px] -ms-3 h-[100%] w-[100%] -z-1"></div>
                            <img src="/images/about-me.jpg" className="rounded-full shadow-md shadow-gray-200 dark:shadow-gray-800" alt="" />

                            <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                                <div className="absolute lg:bottom-18 md:bottom-10 bottom-6 ltr:lg:-right-16 rtl:lg:-left-16 ltr:md:-right-8 rtl:md:-left-8 ltr:right-0 rtl:left-0 p-4 rounded-lg shadow-md dark:shadow-gray-800 bg-white dark:bg-slate-900 m-3 w-44 text-center">
                                    <h6 className="font-semibold">Frontend Developer</h6>
                                    <span className="text-2xl font-medium text-amber-500 mb-0"><span className="counter-value font-bold" data-target="7"><CountUp
                                        start={1}
                                        className="counter-value"
                                        end={3}
                                        duration={1} /></span>+</span>
                                    <span className="text-sm text-slate-400">Years <br /> Experience</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>

                    <div className="lg:col-span-7">
                        <div className="lg:ms-5">
                            <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">I'm a Passionate Frontend Developer</h3>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                <p className="text-slate-400 max-w-xl text-[15px]">As a Frontend Developer with over 3 years of experience, I specialize in turning complex ideas into intuitive and effective web applications. My expertise spans React and Laravel, contributing to a diverse portfolio that includes everything from company profiles and e-Procurements to national-level educational platforms.</p>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                <p className="text-slate-400 max-w-xl text-[15px] mt-6">I blend technical skill with user-centric design to create solutions that are not just functional but also engaging and easy to use.</p>
                            </ScrollAnimation>

                            <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={400}>
                                <div className="mt-6">
                                    <Link to="project" className="btn bg-amber-500/10 hover:bg-amber-500 border-amber-500/10 hover:border-amber-500 text-amber-500 hover:text-white rounded-md me-2 mt-2">See Work</Link>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true} delay={300}>
                        <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">My Expertise</h3>
                    </ScrollAnimation>

                    <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={300}>
                        <p className="text-slate-400 max-w-xl mx-auto text-[15px]">In the ever-evolving landscape of web development, I have honed my skills with a diverse array of powerful technologies, each contributing to my ability to deliver sophisticated and impactful digital solutions.</p>
                    </ScrollAnimation>
                </div>

                <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    {expertiseData.map((item, index) => {
                        // const Icon = item.Icon
                        const Image = item.Image
                        return (
                            <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={(index + 1) * 100 + 300}>
                                <div className="h-full px-6 py-10 shadow shadow-gray-200 hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500 rounded-2xl bg-white dark:bg-slate-900" key={index}>
                                    {/* <Icon className="h-10 w-10 stroke-1 text-amber-500" /> */}
                                    <Image />

                                    <div className="content mt-4">
                                        <h5 className="title text-[17px] font-medium">{item.title}</h5>
                                        <p className="text-slate-400 mt-3 text-[15px]">
                                            {item.desc}
                                        </p>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}