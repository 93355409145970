export const H3 = ({ children }) => {
    return (
        <h3 className="font-semibold lg:text-2xl text-xl lg:leading-normal leading-normal text-slate-900 dark:text-slate-200 mb-4 mt-6">
            {children}
        </h3>
    )
}

export const H4 = ({ children }) => {
    return (
        <h4 className="font-semibold lg:text-xl text-lg lg:leading-normal leading-normal text-slate-900 dark:text-slate-200 mb-3 mt-5">
            {children}
        </h4>
    )
}

export const H5 = ({ children }) => {
    return (
        <h5 className="font-semibold lg:text-lg text-md lg:leading-normal leading-normal text-slate-900 dark:text-slate-200 mb-2 mt-4">
            {children}
        </h5>
    )
}

export const P = ({ children }) => {
    return (
        <p className='text-slate-900 dark:text-slate-400 mb-4' style={{
            fontSize: '1.25rem',
            lineHeight: '32px'
        }}>
            {children}
        </p>
    )
}

export const Image = ({ src, alt }) => {
    return (
        <p className='mb-2'>
            <img
                src={src}
                alt={alt}
                className="w-full rounded-lg shadow shadow-gray-200 hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 transition duration-500"
            />
        </p>
    )
}