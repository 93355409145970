import React, { useEffect } from "react";
import * as Unicons from '@iconscout/react-unicons'
import { Link } from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';
import { useForm } from '@formspree/react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function GetInTouch() {
    const formRef = React.useRef();
    const [state, handleSubmit] = useForm("mleqeepz");

    const [successMsg, setSuccessMsg] = React.useState(null);

    useEffect(() => {
        if (state.succeeded) {
            setSuccessMsg("Thank you for your message. I'll get back to you soon!");
        }

        formRef.current.reset();

        const timeout = setTimeout(() => {
            setSuccessMsg(null);
        }, 5000);

        return () => clearTimeout(timeout);
    }, [state.succeeded])

    return (
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
            <div className="container">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <ScrollAnimation animateIn="animate__fadeInDown" animateOnce={true} delay={200}>
                        <h3 className="mb-6 md:text-2xl text-xl md:leading-normal leading-normal font-semibold">Get In Touch !</h3>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} delay={200}>
                        <p className="text-slate-400 max-w-xl mx-auto text-[15px]">Whether you're looking to build an innovative website, need a hand in fine-tuning your existing platform, or just want to bounce some ideas around, I'm here for you. Let's collaborate to make your vision come alive!</p>
                    </ScrollAnimation>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]">
                    <div className="lg:col-span-8">
                        <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} delay={200}>
                            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                                {!!successMsg && (
                                    <Alert severity="success" className="mb-5">
                                        <AlertTitle>Message Successfully Sent</AlertTitle>
                                        {successMsg}
                                    </Alert>
                                )}

                                <form ref={formRef} onSubmit={handleSubmit}>
                                    <div className="grid lg:grid-cols-12 lg:gap-5">
                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="name" className="text-slate-400 text-[15px] mb-2 block">Name</label>
                                            <input name="name" id="name" type="text" className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]" disabled={state.submitting} required />
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="email" className="text-slate-400 text-[15px] mb-2 block">Email</label>
                                            <input name="email" id="email" type="email" className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]" disabled={state.submitting} required />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <label htmlFor="subject" className="text-slate-400 text-[15px] mb-2 block">Subject</label>
                                            <input name="subject" id="subject" className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-10 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]" disabled={state.submitting} required />
                                        </div>

                                        <div className="mb-5">
                                            <label htmlFor="comments" className="text-slate-400 text-[15px] mb-2 block">Comment</label>
                                            <textarea name="comments" id="comments" className="form-input w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded h-28 outline-none bg-transparent focus:border-amber-500/50 dark:focus:border-amber-500/50 focus:shadow-none focus:ring-0 text-[15px]" disabled={state.submitting} required></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="btn bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600 text-white rounded-md h-11 justify-center flex items-center" disabled={state.submitting}>
                                        {state.submitting && (
                                            <Unicons.UilSpinner className="block rounded-full text-xl animate-spin mr-2" />
                                        )}
                                        Send Message
                                    </button>
                                </form>
                            </div>
                        </ScrollAnimation>
                    </div>

                    <div className="lg:col-span-4">
                        <div className="lg:ms-8">
                            <div className="flex">
                                <div className="icons text-center mx-auto">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={200}>
                                        <Unicons.UilPhone className=" block rounded text-2xl dark:text-white mb-0" />
                                    </ScrollAnimation>
                                </div>

                                <div className="flex-1 ms-6">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={200}>
                                        <h5 className="text-[17px] dark:text-white mb-2 font-medium">Phone</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <Link to="https://api.whatsapp.com/send?phone=628123456789" target="_blank" className="text-slate-400 text-[15px]">+62 857-2171-0334</Link>
                                    </ScrollAnimation>
                                </div>
                            </div>

                            <div className="flex mt-4">
                                <div className="icons text-center mx-auto">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={200}>
                                        <Unicons.UilEnvelope className=" block rounded text-2xl dark:text-white mb-0" />
                                    </ScrollAnimation>
                                </div>

                                <div className="flex-1 ms-6">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={200}>
                                        <h5 className="text-[17px] dark:text-white mb-2 font-medium">Email</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <Link to="agus.stiawan2211@gmail.com" className="text-slate-400 text-[15px]">agus.stiawan2211@gmail.com</Link>
                                    </ScrollAnimation>
                                </div>
                            </div>

                            <div className="flex mt-4">
                                <div className="icons text-center mx-auto">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={200}>
                                        <Unicons.UilMapMarker className=" block rounded text-2xl dark:text-white mb-0" />
                                    </ScrollAnimation>
                                </div>

                                <div className="flex-1 ms-6">
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={200}>
                                        <h5 className="text-[17px] dark:text-white mb-2 font-medium">Location</h5>
                                    </ScrollAnimation>
                                    <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} delay={300}>
                                        <Link to="https://maps.app.goo.gl/po8U2syJZYVDw3w3A" target="_blank" className="text-slate-400 text-[15px] mb-2">Gresik, Indonesia 🇮🇩</Link>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
