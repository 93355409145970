import React from 'react'
import { H3, H4, Image, P } from './_components'

const UmSbyForm = () => {
    return (
        <>
            <H3>Overview</H3>

            <P>This project involved developing a specialized website for form submissions, primarily used for attendee registration at events such as seminars and workshops at the University of Muhammadiyah Surabaya (UM Surabaya). The website was designed to replace the use of Google Forms, offering a more integrated and tailored solution for the university's needs.</P>

            <H3>Tech Stack</H3>

            <H4>Laravel</H4>
            <P>Used to build a robust backend, Laravel facilitated efficient data handling and form processing, ensuring a stable and secure platform.</P>

            <H4>Bootstrap 4</H4>
            <P>This front-end framework was instrumental in crafting a responsive and user-friendly interface, making form submission straightforward and accessible.</P>

            <H4>jQuery</H4>
            <P>This front-end framework was instrumental in crafting a responsive and user-friendly interface, making form submission straightforward and accessible.</P>

            <H4>MySQL</H4>
            <P>As the database solution, MySQL efficiently managed the storage and retrieval of form data, ensuring data integrity and reliability.</P>

            <H3>Visual</H3>

            <H4>Form Detail</H4>
            <P>Presents the detailed layout of each form, showcasing the user-friendly design.</P>
            <Image
                src="/images/works/um-sby-form/1-form.png"
                alt="Form Detail"
            />

            <H4>Form Structure</H4>
            <P>Illustrates the structural layout of the forms, emphasizing ease of navigation.</P>
            <Image
                src="/images/works/um-sby-form/2-form-structure.png"
                alt="Form Structure"
            />

            <H4>Input Text</H4>
            <P>Showcases various types of input field like text.</P>
            <Image
                src="/images/works/um-sby-form/3-text.png"
                alt="Input Text"
            />

            <H4>Input E-Mail</H4>
            <P>Showcases various types of input field like email.</P>
            <Image
                src="/images/works/um-sby-form/4-email.png"
                alt="Input E-Mail"
            />

            <H4>Input File Upload</H4>
            <P>Showcases various types of input field like file upload.</P>
            <Image
                src="/images/works/um-sby-form/5-file.png"
                alt="Input File Upload"
            />

            <H4>Input Signature</H4>
            <P>A unique feature allowing respondents to add their handwritten signature using an HTML canvas</P>
            <Image
                src="/images/works/um-sby-form/6-sign.png"
                alt="Input Signature"
            />

            <H4>Respondent Form</H4>
            <P>Displays the form as seen by respondents, highlighting the clarity and simplicity of the user interface.</P>
            <Image
                src="/images/works/um-sby-form/7-respondent-form.png"
                alt="Respondent Form"
            />

            <H4>Submit Form Success</H4>
            <P>Demonstrates the successful submission interface, providing users with a clear confirmation.</P>
            <Image
                src="/images/works/um-sby-form/8-success.png"
                alt="Respondent Form"
            />
        </>
    )
}

export default UmSbyForm